import { getCookie } from "../utils/cookie-service";
const companyId = getCookie("hrms-companyId");
const employeeId = getCookie("hrms-employeeId");
console.log(employeeId, "emplid");
export const API_ENDPOINT = {
  admin: {
    login: "/login",
  },
  ess: {
    login: "/login",
    leave: {
      managerApprovalLeaveRequestList: "/leave/request",
      managerApprovalLeaveRequestAdd: "/leave/request/leave-request-action",
      empLeaveRequestList: "/leave/application",
      leaveApprove: `leave/request`,
      leaveStatistic: `leave/statistic`,
      leaveTypeList: `company/${companyId}/leave-type`,
      leaveTypeListAdminGet: `leave/get-employee-leave-type`,
      leaveTypeListUser: `leave/get-employee-leave-type`,
    },
    employee: {
      employeeListReports: "employee",
    },
    attendance: {
      punch: "attendance/punch",
      leaveRequest: "/leave/application",
      leaveBalance: "/ess/leave-balance",
      calendarView: "ess/attendance/calendar-view",
      attendanceAnalytics: "ess/attendance/analytics",
      regularizationAttandance: "attendance/regularization",
      missedPunchApproverUpdate: "attendance/regularization-action",
      missedPunchList: "attendance/regularization",
      missedPunchApproverAdd: "attendance/regularization",
      missedPunchApproverList: "attendance/regularization",
      missedPunchApproveAll: "attendance/regularization/action",
    },
    assets: {
      assetsRequest: `/asset-request`,
      updateAssetsRequest: `/asset-request`,
      deleteAssetsRequest: `/asset-request`,
      myAssets: `/asset-request`,
      managerList: `/asset/reports/requests`,
      managerApprovalAssetsAdd: `/asset/requests/action`,
      assetsGroupList: `company/${companyId}/asset-group`,
    },
    // essExitProcess
    essExitProcess: {
      myApplicationExitList: "/exitess/exitNewApplicationList/1",
      myApplicationExitAdd: "/exitess/addExitNewApplication",
      employeeMasterData: "/employee/getEmployee/1",
      myApplicationEmployeeData: "/exitess/getExitNewApplication/21",
      exitApprovalApplicationAdd: "/exitess/addExitApprovalApplication",
      ExitClearanceEmail: "/exitess/addExitEssEmployeeClearance",
      ExitCleranceList: "exitess/exitNewApplicationList/1",
      ExitSupervisorClearance: "exitess/addExitEssApprovalClearance",

      //Revoke process exit
      revokeAdd: "revoke/addExitRevokeApplication",
      //Ess Employee Exit Approval
      // Exit approval Part
      ExitApprovalApplicationadd: "",
      ExitApprovalApplicationList: "",
      ExitApprovalApplicationUpdate: "",
    },
    // essReimburshmentProcess
    essReimburshmentProcess: {
      travelTypeList: "reimbursement/getTravelType",
      travelRequisitionAdd: "essReimbursement/addTravelRequistion",
      travelRequisitionUpdate: "essReimbursement/updateTravelRequistion",
      travelRequisitionList: "essReimbursement/getEmployeeTravelRequisition",
      travelRequisitionDelete: "essReimbursement/deleteTravelRequistion",
      travelDetailsAdd: "essReimbursement/addTravelDetails",
      travelDetailsUpdate: "essReimbursement/addTravelDetails",
      travelDetailsUpdate: "essReimbursement/updateTravelDetails",
      travelDetailsList: "essReimbursement/travelDetailsList",
      travelDetailsgetById: "essReimbursement/deleteTravelDetails",
      travelDetailsDelete: "essReimbursement/deleteTravelDetails",
      accomodationAdd: "essReimbursement/addAccommodation",
      accomodationUpdate: "essReimbursement/updateAccommodation",
      accomodationDelete: "essReimbursement/deleteAccommodation",
      accomodationList: "essReimbursement/accommodationList",
      accomodationgetById: "essReimbursement/getAccommodationDetails",
      uploadDocumentAdd: "essReimbursement/addDocument",
      uploadDocumentUpdate: "essReimbursement/updateDocument",
      uploadDocumentDelete: "essReimbursement/deleteDocument",
      uploadDocumentList: "essReimbursement/documentList",
      uploadDocumentgetById: "essReimbursement/getDocumentDetails",
      claimLtaAdd: "reimbursement/addReimbursementClaimLta",
      otherLtaAdd: "reimbursement/addReimbursementOhterLta",
    },
  },
  forgot_password: "",
  otp_request: "",
  reset_password: "",
  register: "",
  master: {
    stateList: `/state`,
    countryList: `/country`,
    masterListCompanyId: "masterlist/masterList",
    masterCompanyInformationAdd: "/company",
    masterCompanyGetByIdList: `/company`,
    masterCompanyInformationUpdate: `/company/${companyId}`,
    masterCompanyInformationgetById: "/company",
    masterCompanyDocumentAdd: `/company/${companyId}/document`,
    masterCompanyDocumentList: "/company",
    masterCompanyDocumentDelete: `company/${companyId}/document`,
    masterCompanyDocumentUpdate: `company/${companyId}/document`,
    masterCompanyStatutoryAdd: `/company/${companyId}/statutory`,
    masterCompanyStatutoryUpdate: `/company/${companyId}/statutory`,
    masterCompanyStatutoryById: `/company/${companyId}`,
    masterCompanySetting: `company/${companyId}/setting`,
    masterCompanySettingUpdate: `company/${companyId}/setting`,
    masterCompanySettingById: `company/${companyId}/setting`,
    getGrade: "master/getGrade",
    gradeList: `/company/${companyId}/grade`,
    addGrade: `/company/${companyId}/grade`,
    updateGrade: `/company/${companyId}/grade`,
    deleteGrade: `/company/${companyId}/grade`,
    departmentList: `/company/${companyId}/department`,
    getDepartment: "master/getDepartment",
    addDepartment: `/company/${companyId}/department`,
    updateDepartment: `/company/${companyId}/department`,
    deleteDepartment: `/company/${companyId}/department`,
    designationList: `/company/${companyId}/designation`,
    getDesignation: "master/getDesignation",
    addDesignation: `/company/${companyId}/designation`,
    updateDesignation: `/company/${companyId}/designation`,
    deleteDesignation: `/company/${companyId}/designation`,
    // division
    divisionList: `/company/${companyId}/division`,
    getDivision: `/company/${companyId}/division`,
    addDivision: `/company/${companyId}/division`,
    updateDivision: `/company/${companyId}/division`,
    deleteDivision: `/company/${companyId}/division`,
    projectList: `/company/${companyId}/project`,
    getProject: "master/getProject",
    addProject: `/company/${companyId}/project`,
    updateProject: `/company/${companyId}/project`,
    deleteProject: `/company/${companyId}/project`,
    bankList: `/company/${companyId}/bank`,
    getBank: "master/getBank",
    addBank: `/company/${companyId}/bank`,
    updateBank: `/company/${companyId}/bank`,
    deleteBank: `/company/${companyId}/bank`,
    qualificationTypeList: `/company/${companyId}/qualification-type`,
    addQualification: `/company/${companyId}/qualification`,
    qualificationList: `/company/${companyId}/qualification`,
    updateQualification: `/company/${companyId}/qualification`,
    deleteQualification: `/company/${companyId}/qualification`,
    addqualificationType: `/company/${companyId}/qualification-type`,
    updateQualificationType: `/company/${companyId}/qualification-type`,
    deleteQualificationType: `/company/${companyId}/qualification-type`,
    prefixNameList: "master/prefixNameList",
    addPrefixName: "master/addPrefixName",
    updatePrefixName: "master/updatePrefixName",
    getPrefixName: "master/getPrefixName",
    deletePrefixName: "master/deletePrefixName",
    payrollCycleList: "master/payrollCycleList",
    getPayrollCycle: "master/getPayrollCycle",
    addPayrollCycle: "master/addPayrollCycle",
    updatePayrollCycle: "master/updatePayrollCycle",
    deletePayrollCycle: "master/deletePayrollCycle",
    idProofList: "master/idProofList",
    getIdProof: "master/getIdProof",
    addIdProof: "master/addIdProof",
    updateIdProof: "master/updateIdProof",
    deleteIdProof: "master/deleteIdProof",
    getDocumentType: "master/getDocument",
    documentTypeList: `/company/${companyId}/document-type`,
    addDocumentType: `/company/${companyId}/document-type`,
    updateDocumentType: `/company/${companyId}/document-type`,
    deleteDocumentType: `/company/${companyId}/document-type`,
    branchList: `/company/${companyId}/branch`,
    getBranch: "master/getBranch",
    addBranch: `/company/${companyId}/branch`,
    editBranchList: `/company/${companyId}/branch`,
    updateBranch: `/company/${companyId}/branch`,
    deleteBranch: `/company/${companyId}/branch`,
    companyList: "/company",
    getCompany: "company",
    addCompany: "company/addCompany",
    updateCompany: "company/updateCompany",
    deleteCompany: "company/deleteCompany",
    getCategory: "master/getCategory",
    addCategory: `/company/${companyId}/category`,
    categoryList: `/company/${companyId}/category`,
    updateCategory: `/company/${companyId}/category`,
    deleteCategory: `/company/${companyId}/category`,
    lookUpList: "master/itemList",
    getCostCenter: "master/getCostCenter",
    costCenterList: `/company/${companyId}/cost-center`,
    addCostCenter: `/company/${companyId}/cost-center`,
    updateCostCenter: `/company/${companyId}/cost-center`,
    deleteCostCenter: `/company/${companyId}/cost-center`,
    getSkill: "master/getSkill",
    skillList: `/company/${companyId}/skill`,
    addSkill: `/company/${companyId}/skill`,
    updateSkill: `/company/${companyId}/skill`,
    deleteSkill: `/company/${companyId}/skill`,
    getBonus: "master/getBonus",
    bonusList: `/company/${companyId}/bonus`,
    addBonus: `/company/${companyId}/bonus`,
    updateBonus: `/company/${companyId}/bonus`,
    deleteBonus: `/company/${companyId}/bonus`,
    bonusCategoryList: `/company/${companyId}/bonus-category`,
    addBonusCategory: `/company/${companyId}/bonus-category`,
    updateBonusCategory: `/company/${companyId}/bonus-category`,
    deleteBonusCategory: `/company/${companyId}/bonus-category`,

    // location
    locationList: `/company/${companyId}/location`,
    locationAdd: `/company/${companyId}/location`,
    locationGet: `company/${companyId}/location`,
    locationUpdate: `/company/${companyId}/location`,
    locationDelete: `/company/${companyId}/location`,

    // currency
    currencyList: `/currency`,
    currencyAdd: `/currency`,
    currencyGetById: `/currency`,
    currencyUpdate: `/currency`,
    currencyDelete: `/currency`,

    // state
    stateList: `/state`,
    stateAdd: `/state`,
    stateGetById: `/state`,
    stateUpdate: `/state`,
    stateDelete: `/state`,

    //cron
    cronList: `company/${companyId}/cron`,
    cronAdd: `company/${companyId}/cron`,
    cronUpdate: `company/${companyId}/cron`,
    crondelete: `company/${companyId}/cron`,

    // Country
    countryList: `/country`,
    countryAdd: `/country`,
    countryGetById: `/country`,
    countryUpdate: `/country`,
    countryDelete: `/country`,

    // gender
    genderList: `company/${companyId}/gender`,
    genderAdd: `company/${companyId}/gender`,
    genderGetById: `company/${companyId}/gender`,
    genderUpdate: `company/${companyId}/gender`,
    genderDelete: `company/${companyId}/gender`,

    // attandance source
    sourceList: `company/${companyId}/attendance-source`,
    sourceAdd: `company/${companyId}/attendance-source`,
    sourceUpdate: `company/${companyId}/attendance-source`,
    sourceDelete: `company/${companyId}/attendance-source`,

    // branch attendance Setting
    branchAttendaceSettingAdd: `company/${companyId}/branch-attendance`,
    branchAttendaceSettingUpdate: `company/${companyId}/branch-attendance`,
    branchAttendaceSettingDelete: `company/${companyId}/branch-attendance`,
    branchAttendaceSettingGetById: `company/${companyId}/branch-attendance`,
  },
  employee: {
    employeeList: "employee",
    employeeByCode: "reimbursement/getEmployeeDetails",
    addEmployee: "employee",
    updateEmployee: "employee",
    getEmployee: `employee`,
    deleteEmployee: `employee`,
    getEmployeeWorkExperienceList: `/employee`,
    addEmployeeWorkExperience: `/employee`,
    updateEmployeeWorkExperience: `/employee`,
    deleteEmployeeWorkExperience: `/employee`,
    getEmployeeSkills: `/employee`,
    addEmployeeSkill: `/employee`,
    updateEmployeeSkill: `/employee`,
    deleteEmployeeSkill: `/employee`,
    employeeQualificationList: `/employee`,
    addEmployeeQualification: `/employee`,
    updateEmployeeQualification: `/employee`,
    deleteEmployeeQualification: `/employee`,
    empAddress: `employee`,
    personalDetail: `employee`,
    governmentDetail: `employee`,
    governmentDetailList: `employee/${employeeId}/government-detail`,
    getEmployeeHobbies: `/employee`,
    addEmployeeHobby: `/employee`,
    updateEmployeeHobby: `/employee`,
    deleteEmployeeHobby: `/employee`,
    getEmployeeAchievements: `/employee`,
    addEmployeeAchievement: `/employee`,
    updateEmployeeAchievement: `/employee`,
    deleteEmployeeAchievement: `/employee`,
    getEmployeeContactDetails: "employee/getEmployeeContactDetails",
    addEmployeeContactDetails: "employee/addEmployeeContactDetails",
    updateEmployeeContactDetail: "employee/updateEmployeeContactDetails",
    getEmployeeDependentList: `employee`,
    getEmployeeDependentListEmpId: "employee/getEmployeeDependentList",
    getEmployeeDependent: `employee/${employeeId}/dependant`,
    addEmployeeDependent: `employee`,
    updateEmployeeDependent: `employee`,
    deleteEmployeeDependent: `employee`,
    employeeDocumentAdd: `employee`,
    employeeDocumentUpdate: `employee`,
    statutoryList: `employee`,
    employeeStatutoryAdd: `employee`,
    employeeStatutoryGetById: `employee/${employeeId}/statutory`,
    employeeStatutoryUpdate: `employee/${employeeId}/statutory`,
    employeeMicsAllList: `employee`,
    employeeMicsDelete: `employee`,

    // employee Medical
    employeeMedicalAdd: `employee`,
    employeeMedicalUpdate: `employee`,
    employeeMedicalDelete: `employee`,
    employeeMedicalInsuranceList: `employee`,

    // Insurance Plan
    InsurancePlanList: `company/${companyId}/insurance-plan`,
    InsurancePlanAdd: `company/${companyId}/insurance-plan`,
    InsurancePlanGetById: `company/${companyId}/insurance-plan`,
    InsurancePlanUpdate: `company/${companyId}/insurance-plan`,
    InsurancePlanDelete: `company/${companyId}/insurance-plan`,

    // Insurance Company
    InsuranceCompanyList: `company/${companyId}/insurance-company`,
    InsuranceCompanyAdd: `company/${companyId}/insurance-company`,
    InsuranceCompanyGetById: `company/${companyId}/insurance-company`,
    InsuranceCompanyUpdate: `company/${companyId}/insurance-company`,
    InsuranceCompanyDelete: `company/${companyId}/insurance-company`,

    // Email
    EmailList: `employee`,
    EmailAdd: `employee`,
    EmailDelete: `employee`,
    getEmail: `employee`,
    EmailGetById: `employee`,
    EmailUpdate: `employee`,
    EmailDelete: `employee`,

    // Contact Details
    ContactList: `employee`,
    ContactAdd: `employee`,
    deleteContact: `employee`,
    getContact: `employee`,
    ContactGetById: `employee/${employeeId}/contact`,
    ContactUpdate: `employee/${employeeId}/contact`,
    ContactDelete: `employee/${employeeId}/contact`,
  },
  attendance: {
    leaveTypeList: `company/${companyId}/leave-type`,
    getLeaveType: `company/${companyId}/leave-type`,
    addLeaveType: `company/${companyId}/leave-type`,
    updateLeaveType: `company/${companyId}/leave-type`,
    deleteLeaveType: `company/${companyId}/leave-type`,
    leaveList: `company/${companyId}/leave-type`,
    getLeave: "attendance/getLeave",
    addLeaveSetting: `company/${companyId}/leave-type-setting`,
    updateLeaveSetting: `company/${companyId}/leave-type-setting`,
    deleteLeaveSetting: `company/${companyId}/leave-type-setting`,
    listLeaveSetting: `company/${companyId}/leave-type-setting`,
    getByIdLeaveSetting: `company/${companyId}/leave-type-setting`,
    leaveStatistic: `leave/statistic`,
    updateLeave: "attendance/updateLeave",
    deleteLeave: "attendance/deleteLeave",
    getHoliday: "attendance/getHoliday",
    addHoliday: "attendance/addHoliday",
    // leave part

    approveRejectLeaves: "attendance/approveRejectLeaves",

    empAttendanceList: "/attendance/empAttendanceList",
    empLeaveAnalyticsList: "/attendance/empLeaveAnalyticsList",
    empLeaveBalanceList1: "/attendance/empLeaveBalanceList1",
    empLeaveBalance: "/attendance/empLeaveBalance",
    empLeaveList: "/attendance/empLeaveList",
    addManualLeaveRequest: "/attendance/addManualLeaveRequest",
    getallAttendanceList: "/attendance/getallAttendanceList",
  },
  leave: {
    leaveStatistic: "/leave/statistic",
    adminLeaveRequestList: "/leave/request",
  },
  holiday: {
    holidayType: `company/${companyId}/holiday-type`,
    holidayTypeList: `company/${companyId}/holiday-type`,
    holidayTypeDelete: `company/${companyId}/holiday-type`,
    holidayTypeUpdate: `company/${companyId}/holiday-type`,
    holidayAdd: `company/${companyId}/holiday`,
    holidayUpdate: `company/${companyId}/holiday`,
    holidayList: `company/${companyId}/holiday`,
    holidayDelete: `company/${companyId}/holiday`,
    holidayGetById: `company/${companyId}/holiday`,
  },
  shift: {
    shiftList: `company/${companyId}/shift`,
    getShift: `company/${companyId}/shift`,
    addShift: `company/${companyId}/shift`,
    updateShift: `company/${companyId}/shift`,
    deleteShift: `company/${companyId}/shift`,

    addShiftAssiged: "attendance/addEmployeeAssignShift",

    /* new as per nilansh api */
    shiftAssigedList: `employee/${employeeId}/shift`,
    assignShiftAdd: `company/${companyId}/shift-assign`,
    assignShiftUpdate: `company/${companyId}/shift-assign`,
  },

  email: {
    emailTemplateAdd: `company/${companyId}/notification-template`,
    emailTemplateUpdate: `company/${companyId}/notification-template`,
    emailTemplateList: `company/${companyId}/notification-template`,
    emailTemplateDelete: `company/${companyId}/notification-template`,
  },

  assets: {
    assetsGroupList: `company/${companyId}/asset-group`,
    addAssetsGroup: `company/${companyId}/asset-group`,
    updateAssetsGroup: `company/${companyId}/asset-group`,
    deleteAssetsGroup: `company/${companyId}/asset-group`,

    assetsMasterList: `company/${companyId}/asset`,
    addAsset: `company/${companyId}/asset`,
    updateAsset: `company/${companyId}/asset`,
    deleteAsset: `company/${companyId}/asset`,
    getAsset: `company/${companyId}/asset`,

    // admin request list api
    assetsRequestList: "asset/reports/requests",
    assetsAssign: `company/${companyId}/asset-movement`,
    assetsMovementList: `company/${companyId}/asset-movement`,
    assetsCheckAssetsIssued: `company/${companyId}/asset-movement`,
    addAssetMovement: `company/${companyId}/asset-movement`,

    assetsRequisitionList: "asset/assetRequisitionList",
  },
  exit: {
    // master list
    exitMasterTypeList: "exitType/exitTypeMasterList",
    exitDelete: "exitType/deleteExitTypeMaster",
    exitPost: "exitType/addExitTypeMaster",
    exitUpdate: "exitType/updateExitTypeMaster",
    // clearence list
    exitClearanceDepartmentList: "/approval/exitApprovalList",
    exitClearanceDepartmentPost: "/approval/addExitApproval",
    exitClearanceDepartmentDelete: "/approval/deleteExitApprovalGroup",
    exitClearanceDepartmentUpdate: "/approval/updateExitApprovalGroup",
    // notice list
    noticePeriodList: "noticeperiod/exitNoitcePeriodList",
    noticePeriodDelete: "noticeperiod/deleteExitNoitcePeriod",
    noticePeriodUpdate: "noticeperiod/updateExitNoitcePeriod",
    noticePeriodAdd: "noticeperiod/addExitNoitcePeriod",
    // InterViewIn list
    interViewInchargeList: "interview/interviewGroupList",
    interViewInchargeDelete: "interview/deleteInterviewGroup",
    interViewInchargeUpdate: "interview/updateInterviewGroup",
    interViewInchargeAdd: "interview/addInterviewGroup",
    // Clearance Descripition
    clearanceDepartmentList: "exitdepartment/exitDepartmentClearanceList",
    clearanceDepartmentDelete: "exitdepartment/deleteExitDepartmentClearance",
    clearanceDepartmentUpdate: "exitdepartment/updateExitDepartmentClearance",
    clearanceDepartmentAdd: "exitdepartment/addExitDepartmentClearance",
    // Questions Part
    questionsList: "exitquestion/exitTypeQuestionList",
    questionsDelete: "exitquestion/deleteExitTypeQuestion",
    questionsUpdate: "exitquestion/updateExitTypeQuestion",
    questionsAdd: "exitquestion/addExitTypeQuestion",
    questionsHeadingAdd: "exitType/addExitHeading",
    questionsHeadingUpdate: "exitType/updateExitHeading",
    questionsHeadingList: "exitType/exitHeading",
    questionsHeadingDelete: "exitType/deleteExitHeading",
    questionsTypeAdd: "exitType/addExitQuestion",
    questionsTypeUpdate: "exitType/updateExitQuestion",
    questionsTypeList: "exitType/exitQuestion",
    questionsTypeDelete: "exitType/deleteExitQuestion",
  },
  // payroll
  payroll: {
    payrollMasterListCompanyId: "payroll/ctcpayrollMasterList",
    payrollMasterHeaderItemList: "payroll/empIncremenTArrearEmployeeFilterList",
    //type of head master
    typeOfHead: `/company/${companyId}/salary-head-payment-type`,
    // salary head
    payrollSalaryHeadList: `/company/${companyId}/salary-head`,
    salaryHeadDelete: `/company/${companyId}/salary-head`,
    salaryHeadPost: `/company/${companyId}/salary-head`,
    salaryHeadUpdate: `/company/${companyId}/salary-head`,
    payrollSalaryHeadId: `/company/${companyId}/salary-head`,
    // Deducation head
    payrollDeductionHeadList: `/company/${companyId}/salary-head`,
    payrollDeductionHeadId: "payroll/getDeductionHead",
    DeductionHeadDelete: "payroll/deleteDeductionHead",
    DeductionHeadPost: "payroll/addDeductionHead",
    DeductionHeadUpdate: "payroll/updateDeductionHead",

    // Other head
    payrollOtherHeadList: `/company/${companyId}/salary-head`,
    payrollOtherHeadDelete: "payroll/deleteOtherHead",
    payrollOtherHeadAdd: "payroll/addOtherHead",
    payrollOtherHeadUpdate: "payroll/updateOtherHead",
    payrollOtherHeadId: "payroll/getOtherHead",

    // Gournal Head
    payrollJournalList: "payroll/journalVoucherList",
    payrollJournalDelete: "payroll/deleteJournalVoucher",
    payrollJournalAdd: "payroll/addJournalVoucher",
    payrollJournalUpdate: "payroll/updateJournalVoucher",

    // OverTime
    OvertimeList: `/company/${companyId}/salary-over-time-calculation`,
    OvertimeAdd: `/company/${companyId}/salary-over-time-calculation`,
    OvertimeUpdate: `/company/${companyId}/salary-over-time-calculation`,
    OvertimeDelete: `/company/${companyId}/salary-over-time-calculation`,

    // OtUploadAttandence
    OtAttendanceList: "payroll/otAttendanceAllList",
    OtAttendanceAdd: "payroll/addOtAttendance",
    OtAttendanceUpdate: "payroll/updateOtAttendance",
    OtAttendanceDelete: "payroll/deleteOtAttendance",

    // LWF
    LwfList: `/company/${companyId}/salary-lwf`,
    LwfAdd: `/company/${companyId}/salary-lwf`,
    lwfByIdDetail: `/company/${companyId}/salary-lwf`,
    LwfUpdate: `/company/${companyId}/salary-lwf`,
    LwfDelete: `/company/${companyId}/salary-lwf`,

    // Professional Tax
    ProfessionalTaxList: `/company/${companyId}/salary-pt`,
    ProfessionalTaxAdd: `/company/${companyId}/salary-pt`,
    ProfessionalTaxUpdate: `/company/${companyId}/salary-pt`,
    ProfessionalTaxDelete: `/company/${companyId}/salary-pt`,
    ProfessionalTaxId: `/company/${companyId}/salary-pt`,

    // ctc component setting
    CtcComponentList: `/company/${companyId}/salary-component`,
    CtcComponentAdd: `/company/${companyId}/salary-component`,
    CtcGetDetails: `/company/${companyId}/salary-component`,
    CtcComponentUpdate: `/company/${companyId}/salary-component`,
    CtcComponentDelete: `/company/${companyId}/salary-component`,
    CtcComponentAddId: "payroll/getCtcStructure",
    CtcComponentAddCtc: "payroll/ctcCalculation",
    CtcComponentAddCtcCalculated: "payroll/addSalaryStructureCtc",
    CtcComponentAddCtcSaveData: "payroll/getSalaryStructureCtc",

    // Leave Encashment
    LeaveEncashmentList: `/company/${companyId}/salary-leave-encashment`,
    LeaveEncashmentAdd: `/company/${companyId}/salary-leave-encashment`,
    LeaveEncashmentUpdate: `/company/${companyId}/salary-leave-encashment`,
    LeaveEncashmentDelete: `/company/${companyId}/salary-leave-encashment`,

    // salarystructurehead
    SalaryStructureHeadList: "payroll/salaryStructureHeadList",
    SalaryStructureHeadAdd: "payroll/addSalaryStructureHead",
    SalaryStructureHeadUpdate: "payroll/updateSalaryStructure",
    SalaryStructureHeadDelete: "payroll/deleteSalaryStructure",

    //salaryStructureCtc
    SalaryStructureCtc: "payroll/salaryStructureList",
    SalaryStructureCtcAdd: "payroll/addSalaryStructureHead",
    SalaryStructureCtcUpdate: "payroll/updateSalaryStructureHead",
    SalaryStructureCtcDelete: "payroll/deleteSalaryStructureHead",

    IncrementArrearAdd: `employee`,
    IncrementArrearUpdate: "payroll/updateSalaryIncrement",
    IncrementArrearList: "payroll/salaryIncrementList",
    IncrementArrearListNew: "payroll/salaryArrearList",
    IncrementArrearById: "payroll/getSalaryIncrement",
    IncrementArrearDel: "payroll/deleteSalaryArrear",

    // BonusCalculation
    BonusCalculationList: `company/${companyId}/salary-bonus-calculation`,
    BonusCalculationAdd: `company/${companyId}/salary-bonus-calculation`,
    BonusCalculationDelete: `company/${companyId}/salary-bonus-calculation`,
    BonusCalculationUpdate: `company/${companyId}/salary-bonus-calculation`,
    BonusCalculationGetId: `company/${companyId}/salary-bonus-calculation`,

    // Lwp Arrear
    LwpArrearList: "payroll/salaryArrearlwpList",
    LwpArrearAdd: "payroll/addSalaryArrearlwp",
    LwpArrearUpdate: "payroll/updateSalaryArrearlwp",
    LwpArrearGetId: "payroll/getSalaryArrearlwp",
    LwpArrearDelete: "payroll/deleteSalaryArrearlwp",
    // Lwp Arrear
    OtherArrearList: "payroll/salaryArrearotherList",
    OtherArrearAdd: "payroll/addSalaryArrearother",
    OtherArrearUpdate: "payroll/updateSalaryArrearother",
    OtherArrearGetId: "payroll/getSalaryArrearother",
    OtherArrearDelete: "payroll/deleteSalaryArrearother",

    //Gratuity
    GratuityList: `company/${companyId}/salary-gratuity-calculation`,
    GratuityAdd: `company/${companyId}/salary-gratuity-calculation`,
    GratuityUpdate: `company/${companyId}/salary-gratuity-calculation`,
    GratuityDelete: `company/${companyId}/salary-gratuity-calculation`,
    // Promotion
    promotionAdd: "/employee/employment-detail-change",
    PromotionList: "/employee/employment-detail-change",
    PromotionUpdate: "payroll/updatePromotionTransfer",
    PromotionDelete: "payroll/deletePromotionTransfer",
    PromotionGetId: "payroll/getPromotionTransfer",

    // Employee Increment
    EmployeeIncrementList: `employee/${employeeId}/salary-increment`,
    EmployeeIncrementAdd: `/employee`,
    EmployeeIncrementByEmpId: "payroll/getSalaryStructureEmpList",
    EmployeeIncrementUpdate: "payroll/updateSalaryIncrement",
    EmployeeIncrementDelete: "payroll/deleteSalaryIncrement",
    EmployeeIncrementGetId: "payroll/getSalaryIncrement",
    EmployeeIncrementByIdView: "payroll/getSalaryIncrementEmpList",
    EmployeeIncrementDaysAdd: "payroll/employeeDays",
  },

  reimbursement: {
    travelTypeList: "reimbursement/getTravelTyPeList",
    travelAdd: "reimbursement/addTravelPolicy",
    travelPolicyList: "reimbursement/travelPolicyList",
    travelPolicyDelete: "reimbursement/deleteTravelPolicy",
    approvalMatrixList: "reimbursement/approalMatrixList",
    approvalMatrixAdd: "reimbursement/addApproalMatrix",
    approvalMatrixDelete: "reimbursement/deleteApproalMatrix",
    approvalMatrixUpdate: "reimbursement/updateApproalMatrix",
    approvalMatrixFilterAdd: "reimbursement/addApproalFilterMatrix",
    settingFaceList: "reimbursement/reimbursementSettingList",
    settingFaceAdd: "reimbursement/addReimbursementSetting",
    processAdminAdd: "reimbursement/addReimbursement",
    processAdminUpdate: "reimbursement/updateReimbursement",
    processAdminDelete: "reimbursement/deleteReimbursement",
    processAdminList: "reimbursement/reimbursementList",
    processAdminGetById: "reimbursement/getReimbursementAdd",
  },
  user: {
    adminRole: "user/admin-role",
    essRole: "user/ess-role",
    admin: "user/admin",
    ess: "user/ess",
    userList: "/user",
    userAccess: "/user/settings",
    // show all permissison list
    permissions: "permission",
    essRoleCreate: "role",
    roleList: "role",
    deleteRole: "role",
    updateRole: "role",

    // assign Request
    userList: "user",
    assignRequest: "role/user-assign",
  },
};
