import { useRoutes } from "react-router-dom";
import { lazyLoadRoutes } from "../utils/lazy-loader";
import { onBoardingOuterRoutes } from "./onborading-outer-routes";

export function AuthRoutes() {
  const authRoutes = [
    {
      path: "/",
      children: [
        {
          index: true,
          element: lazyLoadRoutes("auth/login"),
        },
        {
          path: "auth/login",
          element: lazyLoadRoutes("auth/login"),
        },
        {
          path: "auth/forgot-password",
          element: lazyLoadRoutes("auth/forgot-password"),
        },
        ...onBoardingOuterRoutes,
      ],
    },
  ];

  return useRoutes(authRoutes);
}
