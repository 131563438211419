import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

// export const payrollRoutes = [
//   {
//     path: routes.payroll.salaryHead,
//     element: lazyLoadRoutes("payroll/salary-head/SalaryHead"),
//   },
//   {
//     path: routes.payroll.deductionHead,
//     element: lazyLoadRoutes("payroll/deduction-head/DeductionHead"),
//   },
//   {
//     path: routes.payroll.otherHeadAndLinkHead,
//     element: lazyLoadRoutes(
//       "payroll/other-head-and-link-head/OtherHeadAndLinkHead"
//     ),
//   },
//   {
//     path: routes.payroll.branchMaster,
//     element: lazyLoadRoutes("payroll/branch-master/BranchMaster"),
//   },
//   {
//     path: routes.payroll.divisionMaster,
//     element: lazyLoadRoutes("payroll/division-master/DivisionMaster"),
//   },
//   {
//     path: routes.payroll.locationMaster,
//     element: lazyLoadRoutes("payroll/location-master/LocationMaster"),
//   },
//   {
//     path: routes.payroll.departmentMaster,
//     element: lazyLoadRoutes("payroll/department-master/DepartmentMaster"),
//   },
//   {
//     path: routes.payroll.gradeMaster,
//     element: lazyLoadRoutes("payroll/grade-master/GradeMaster"),
//   },
//   {
//     path: routes.payroll.categoryMaster,
//     element: lazyLoadRoutes("payroll/category-master/CategoryMaster"),
//   },
//   {
//     path: routes.payroll.designationMaster,
//     element: lazyLoadRoutes("payroll/designation-master/DesignationMaster"),
//   },
//   {
//     path: routes.payroll.costCenter,
//     element: lazyLoadRoutes("payroll/cost-center/CostCenter"),
//   },
//   {
//     path: routes.payroll.bankMaster,
//     element: lazyLoadRoutes("payroll/bank-master/BankMaster"),
//   },
//   {
//     path: routes.payroll.projectMaster,
//     element: lazyLoadRoutes("payroll/project-master/ProjectMaster"),
//   },
//   {
//     path: routes.payroll.employeeDocumentMaster,
//     element: lazyLoadRoutes(
//       "payroll/employee-document-master/EmployeeDocumentMaster"
//     ),
//   },
//   {
//     path: routes.payroll.bonusCategory,
//     element: lazyLoadRoutes("payroll/bonus-category/BonusCategory"),
//   },
//   {
//     path: routes.payroll.leaveEncashment,
//     element: lazyLoadRoutes("payroll/leave-encashment/LeaveEncashment"),
//   },
//   {
//     path: routes.payroll.salaryStructure,
//     element: lazyLoadRoutes("payroll/salary-structure/SalaryStructure"),
//   },
//   {
//     path: routes.payroll.ctcComponentSelection,
//     element: lazyLoadRoutes(
//       "payroll/ctc-component-selection/CtcComponentSelection"
//     ),
//   },
//   {
//     path: routes.payroll.gratuityCalculation,
//     element: lazyLoadRoutes("payroll/gratuity-calculation/GratuityCalculation"),
//   },
//   {
//     path: `${routes.payroll.salaryStructure}/:action/:empType/:code`,
//     element: lazyLoadRoutes("payroll/salary-structure/SalaryStructureView"),
//   },
//   {
//     path: `${routes.payroll.salaryStructure}/:action/:empType/:code`,
//     element: lazyLoadRoutes("payroll/salary-structure/SalaryStructureView"),
//   },
//   {
//     path: `${routes.payroll.salaryStructure}/:action/:empType/:code`,
//     element: lazyLoadRoutes("payroll/salary-structure/SalaryStructureView"),
//   },
//   {
//     path: `${routes.payroll.promotionAndTransfer}`,
//     element: lazyLoadRoutes(
//       "payroll/promotion-and-transfer/PromotionAndTransfer"
//     ),
//   },
//   {
//     path: `${routes.payroll.employeeIncrement}`,
//     element: lazyLoadRoutes("payroll/employee-increment/EmployeeIncrement"),
//   },
//   {
//     path: `${routes.payroll.employeeIncrement}/:action/:code`,
//     element: lazyLoadRoutes("payroll/employee-increment/EmployeeIncrementView"),
//   },
//   {
//     path: `${routes.payroll.journalVoucherSettings}`,
//     element: lazyLoadRoutes(
//       "payroll/journal-voucher-settings/JournalVoucherSettings"
//     ),
//   },
//   {
//     path: `${routes.payroll.incrementArrear}`,
//     element: lazyLoadRoutes("payroll/increment-arrear/IncrementArrear"),
//   },
//   {
//     path: `${routes.payroll.lwpArrear}`,
//     element: lazyLoadRoutes("payroll/lwp-arrear/LwpArrear"),
//   },
//   {
//     path: `${routes.payroll.otherArrear}`,
//     element: lazyLoadRoutes("payroll/other-arrear/OtherArrear"),
//   },
//   {
//     path: `${routes.payroll.bonusCalculation}`,
//     element: lazyLoadRoutes("payroll/bonus-calculation/BonusCalculation"),
//   },
// ];

export const payrollRoutes = [
  {
    path: routes.payroll.TypeOfHead,
    element: lazyLoadRoutes("payroll/pages/typeOfHead"),
  },
  {
    path: routes.payroll.EarningSalaryHead,
    element: lazyLoadRoutes("payroll/pages/earningSalaryHead"),
  },
  {
    path: routes.payroll.DeducationHead,
    element: lazyLoadRoutes("payroll/pages/deducationHead"),
  },
  {
    path: routes.payroll.EarningSalaryHeadAdd,
    element: lazyLoadRoutes("payroll/pages/earningSalaryHeadAdd"),
  },
  {
    path: `${routes.payroll.EarningSalaryHeadEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/earningSalaryHeadEdit"),
  },
  {
    path: `${routes.payroll.EarningSalaryHeadView}/:id`,
    element: lazyLoadRoutes("payroll/pages/earningSalaryHeadView"),
  },
  {
    path: routes.payroll.DeducationHeadAdd,
    element: lazyLoadRoutes("payroll/pages/deductionaHeadAdd"),
  },
  {
    path: `${routes.payroll.DeducationHeadEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/deducationHeadEdit"),
  },
  {
    path: `${routes.payroll.DeducationHeadView}/:id`,
    element: lazyLoadRoutes("payroll/pages/deducationHeadView"),
  },
  {
    path: routes.payroll.OtherHead,
    element: lazyLoadRoutes("payroll/pages/otherHead"),
  },
  {
    path: routes.payroll.OtherHeadAdd,
    element: lazyLoadRoutes("payroll/pages/otherHeadAdd"),
  },
  {
    path: `${routes.payroll.OtherHeadEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/otherHeadEdit"),
  },
  {
    path: `${routes.payroll.OtherHeadView}/:id`,
    element: lazyLoadRoutes("payroll/pages/otherHeadView"),
  },
  {
    path: routes.payroll.GlCodeSetting,
    element: lazyLoadRoutes("payroll/pages/glCodeSetting"),
  },
  {
    path: routes.payroll.OverTime,
    element: lazyLoadRoutes("payroll/pages/overTime"),
  },
  {
    path: routes.payroll.UploadOtAttendance,
    element: lazyLoadRoutes("payroll/pages/overtime/uploadotAttandance"),
  },
  {
    path: routes.payroll.OverTimeTransferOt,
    element: lazyLoadRoutes("payroll/pages/overtime/transferOvertime"),
  },
  {
    path: routes.payroll.LwfRuleSetting,
    element: lazyLoadRoutes("payroll/pages/lwfRuleSetting"),
  },
  {
    path: routes.payroll.LwfRuleSettingAdd,
    element: lazyLoadRoutes("payroll/pages/lwfRuleSettingAdd"),
  },
  {
    path: `${routes.payroll.LwfRuleSettingEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/lwfRuleSettingEdit"),
  },
  {
    path: routes.payroll.UploadOtAttendance,
    element: lazyLoadRoutes("payroll/pages/uploadOt"),
  },
  {
    path: routes.payroll.ProcessAll,
    element: lazyLoadRoutes("payroll/pages/processAll"),
  },
  {
    path: routes.payroll.SummaryReport,
    element: lazyLoadRoutes("payroll/pages/summaryReport"),
  },
  {
    path: routes.payroll.DetailsReport,
    element: lazyLoadRoutes("payroll/pages/detailsReport"),
  },
  {
    path: routes.payroll.ProfessionalTax,
    element: lazyLoadRoutes("payroll/pages/professionTax"),
  },
  {
    path: routes.payroll.ProfessionalTaxAdd,
    element: lazyLoadRoutes("payroll/pages/professionalTaxAdd"),
  },
  {
    path: `${routes.payroll.ProfessionalTaxEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/professionalTaxEdit"),
  },
  {
    path: routes.payroll.CtcComponentSetting,
    element: lazyLoadRoutes("payroll/pages/ctcComponentSettings"),
  },
  {
    path: routes.payroll.CtcComponentSettingReport,
    element: lazyLoadRoutes("payroll/pages/ctcComponentReport"),
  },
  {
    path: routes.payroll.CtcComponentSettingAdd,
    element: lazyLoadRoutes("payroll/pages/ctcComponentSettingsAdd"),
  },
  {
    path: `${routes.payroll.CtcComponentSettingEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/ctcComponentSettingsEdit"),
  },
  {
    path: `${routes.payroll.CtcComponentSettingView}/:id`,
    element: lazyLoadRoutes("payroll/pages/ctcComponentSettingsView"),
  },
  {
    path: routes.payroll.IncrementArrear,
    element: lazyLoadRoutes("payroll/pages/IncrementArrear/incrementArrear"),
  },
  // {
  //   path: `${routes.payroll.IncrementArrearAdd}/:id`,
  //   element: lazyLoadRoutes("payroll/pages/IncrementArrear/incrementArrearAdd"),
  // },
  {
    path: `${routes.payroll.IncrementArrearAdd}`,
    element: lazyLoadRoutes("payroll/pages/IncrementArrear/incrementArrearAdd"),
  },
  {
    path: `${routes.payroll.IncrementArrearEdit}/:id`,
    element: lazyLoadRoutes(
      "payroll/pages/IncrementArrear/incrementArrearEdit"
    ),
  },
  {
    path: routes.payroll.LeaveEncashment,
    element: lazyLoadRoutes("payroll/pages/LeaveEncashment/leaveEncashment"),
  },
  {
    path: routes.payroll.LeaveEncashmentReport,
    element: lazyLoadRoutes(
      "payroll/pages/LeaveEncashment/leaveEncashmentReport"
    ),
  },
  {
    path: routes.payroll.LeaveEncashmentNotAddBtn,
    element: lazyLoadRoutes(
      "payroll/pages/LeaveEncashment/leaveEncashmentNotAddBtn"
    ),
  },
  {
    path: routes.payroll.AttandanceReport,
    element: lazyLoadRoutes(
      "payroll/pages/AttandanceProcess/attandanceProcess"
    ),
  },
  {
    path: routes.payroll.AttandanceReportView,
    element: lazyLoadRoutes(
      "payroll/pages/AttandanceProcess/attandanceProcessView"
    ),
  },
  {
    path: routes.payroll.SalaryStructure,
    element: lazyLoadRoutes("payroll/pages/SalaryStructure/salaryStructure"),
  },
  {
    path: `${routes.payroll.SalaryStructureAdd}/:id`,
    element: lazyLoadRoutes("payroll/pages/SalaryStructure/salaryStructureAdd"),
  },
  {
    path: `${routes.payroll.SalaryStructureView}/:id`,
    element: lazyLoadRoutes(
      "payroll/pages/SalaryStructure/salaryStructureView"
    ),
  },
  {
    path: routes.payroll.BonusCalculation,
    element: lazyLoadRoutes("payroll/pages/BonusCalculation/bonusCalculation"),
  },
  {
    path: routes.payroll.BonusCalculationProcess,
    element: lazyLoadRoutes(
      "payroll/pages/BonusCalculation/bonusCalculationProcess"
    ),
  },
  {
    path: routes.payroll.BonusCalculationSummary,
    element: lazyLoadRoutes(
      "payroll/pages/BonusCalculation/bonusCalulationReport"
    ),
  },
  {
    path: routes.payroll.BonusCalculationDetails,
    element: lazyLoadRoutes(
      "payroll/pages/BonusCalculation/bonusCalculationDetails"
    ),
  },
  {
    path: routes.payroll.LwpArrear,
    element: lazyLoadRoutes("payroll/pages/lwpArrear/lwpArrear"),
  },
  {
    path: `${routes.payroll.LwpArrearCalculation}`,
    element: lazyLoadRoutes("payroll/pages/lwpArrear/lwpArrearCalculation"),
  },
  {
    path: `${routes.payroll.LwpArrearCalculationEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/lwpArrear/lwpArrearCalculationEdit"),
  },
  {
    path: routes.payroll.OtherArrear,
    element: lazyLoadRoutes("payroll/pages/OtherArrear/otherArrear"),
  },
  {
    path: routes.payroll.OtherArrearCalculation,
    element: lazyLoadRoutes("payroll/pages/OtherArrear/otherArrearCalculation"),
  },
  {
    path: `${routes.payroll.OtherArrearCalculationEdit}/:id`,
    element: lazyLoadRoutes("payroll/pages/OtherArrear/otherArrearEdit"),
  },
  {
    path: routes.payroll.GrautityCalculation,
    element: lazyLoadRoutes(
      "payroll/pages/GrautityCalculation/grautityCalculation"
    ),
  },
  {
    path: routes.payroll.PromotionAndTransfer,
    element: lazyLoadRoutes(
      "payroll/pages/PromotionAndTransfer/promotionAndTransfer"
    ),
  },
  {
    path: routes.payroll.EmployeeIncrement,
    element: lazyLoadRoutes(
      "payroll/pages/EmployeeIncrement/employeeIncrement"
    ),
  },
  {
    path: `${routes.payroll.EmployeeIncrementAdd}/:id`,
    element: lazyLoadRoutes(
      "payroll/pages/EmployeeIncrement/employeeIncrementAdd"
    ),
  },
  {
    path: `${routes.payroll.EmployeeIncrementEdit}/:id`,
    element: lazyLoadRoutes(
      "payroll/pages/EmployeeIncrement/employeeIncrementEdit"
    ),
  },
  {
    path: `${routes.payroll.ArrearBaiseList}`,
    element: lazyLoadRoutes(
      "payroll/pages/ArrearBaiseIncrement/IncrementArrearList"
    ),
  },
  {
    path: `${routes.payroll.ArrearBaiseMoreThanOne}`,
    element: lazyLoadRoutes(
      "payroll/pages/ArrearBaiseIncrement/IncrementArrearMoreThanOne"
    ),
  },
  {
    path: `${routes.payroll.ArrearBaiseView}/:id`,
    element: lazyLoadRoutes(
      "payroll/pages/ArrearBaiseIncrement/IncrementArrearView"
    ),
  },
];
