import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const salarySheetRoutes = [
  {
    path: routes.salarySheet.salarySheetList,
    element: lazyLoadRoutes("salary-sheet/pages/salarySheetList"),
  },
  {
    path: routes.salarySheet.salarySheetSetting,
    element: lazyLoadRoutes("salary-sheet/pages/salarySheetSettingList"),
  },
  {
    path: routes.salarySheet.salarySheetSettingAdd,
    element: lazyLoadRoutes("salary-sheet/pages/salarySheetSettingAdd"),
  },
  {
    path: `${routes.salarySheet.salarySheetSettingEdit}/:id`,
    element: lazyLoadRoutes("salary-sheet/pages/salarySheetSettingEdit"),
  },
  {
    path: `${routes.salarySheet.salarySheetSettingReport}`,
    element: lazyLoadRoutes("salary-sheet/pages/salarySheetReport"),
  },
];
