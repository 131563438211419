import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const userRoutes = [
  {
    path: "admin/user/list",
    element: lazyLoadRoutes("user/pages/admin"),
  },

  {
    path: "ess/user/list",
    element: lazyLoadRoutes("user/pages/ess"),
  },

  {
    path: "admin/role/list",
    element: lazyLoadRoutes("user/pages/admin-role"),
  },

  {
    path: "ess/role/list",
    element: lazyLoadRoutes("user/pages/ess-role"),
  },
  {
    path: routes.users.admin.adminRoleCreate,
    element: lazyLoadRoutes("user/pages/admin-role-create"),
  },
  {
    path: routes.users.ess.essRoleCreate,
    element: lazyLoadRoutes("user/pages/ess-role-create"),
  },
  {
    path: routes.users.ess.essUserAssign,
    element: lazyLoadRoutes("user/pages/ess-user-assign"),
  },
  {
    path: routes.users.ess.mobileRights,
    element: lazyLoadRoutes("user/pages/user-access"),
  },
];
