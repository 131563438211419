import { DEFAULT_ACTION,LOGIN,LOG_OUT} from "./action-types";

export const NOTIFICATION_MESSAGES = Object.freeze({
    [DEFAULT_ACTION]: {
        error404:{
            title: "Error",
            variant: "error",
            message: "URL is not recognized!",
        },
        error409:{
            title: "Error",
            variant: "error",
            message: "Server Error!",
        },
        error401:{
            title: "Error",
            variant: "error",
            message: "Unauthorised user!",
        },
        // error: {
        //     title: "Error",
        //     variant: "error",
        //     message: "Something went wrong!",
        // },
    },
   
    [LOGIN]: {
        success: {
            title: "Login",
            variant: "success",
            message: "Logged in successfully!",
        },
        error: {
            title: "Login",
            variant: "error",
            message: "Invalid Credentials!",
        }
    },
    [LOG_OUT]: {
        title: 'Logout',
        message: 'You have been logged out',
        variant: 'info'
    },
});