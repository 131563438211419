import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const assetsRoutes = [
  {
    path: routes.assets.group,
    element: lazyLoadRoutes("assets/pages/group"),
  },

  {
    path: routes.assets.list,
    element: lazyLoadRoutes("assets/pages/list"),
  },

  {
    path: routes.assets.requisitionList,
    element: lazyLoadRoutes("assets/pages/requisitionList"),
  },

  {
    path: routes.assets.requestList,
    element: lazyLoadRoutes("assets/pages/requestList"),
  },

  {
    path: routes.assets.movementList,
    element: lazyLoadRoutes("assets/pages/movementList"),
  },
  {
    path: routes.assets.uploadDocuments,
    element: lazyLoadRoutes("assets/pages/uploadDocuments"),
  },
  {
    path: `${routes.assets.checkIssuedAssetsList}/:id`,
    element: lazyLoadRoutes("assets/pages/checkAssetsIssued"),
  },
];
