import {
  SHOW_LOADING,
  HIDE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_COMPANY,
  UPDATE_COMPANY_LIST,
  UPDATE_ACTIVE_MONTH,
  UPDATE_SIDEBAR,
} from "../mutations";

const initialState = {
  spinner: 0,
  notification: null,
  showNotification: 0,
  companyList: [],
  companyId: null,
  exitMasterList: [],
  activeMonth: false,
  activeSideBar: true,
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        spinner: action.payload ? state.spinner + 1 : state.spinner - 1,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: 0,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
        showNotification: state.showNotification + 1,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        companyId: action.payload,
      };
    case UPDATE_ACTIVE_MONTH:
      return {
        ...state,
        activeMonth: action.payload,
      };
    case UPDATE_SIDEBAR:
      return {
        ...state,
        activeSideBar: action.payload,
      };
    case UPDATE_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload,
      };
    default:
      return state;
  }
}
