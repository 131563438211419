import React, { useState, useRef, useEffect } from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  ADMIN_SIDEBAR_NAV_MENU_ITEMS,
  ESS_SIDEBAR_NAV_MENU_ITEMS,
  ADMIN_CONTROLS_NAV_MENU_ITEMS,
} from "../../constants/constants";
import { withRouter } from "../../utils/withRouter";
import { useSelector } from "react-redux";

function SideNav(props) {
  const accordionRef = useRef(null);
  const [activeEventKey, setActiveEventKey] = useState([]);
  const [outerActiveEventKey, setOuterActiveEventKey] = useState([]);
  const [innerActiveEventKey, setInnerActiveEventKey] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const inputRef = useRef(null);
  const activeMonth = useSelector((state) => state.global.activeMonth);

  const handleAccordionChange = (eventKey) => {
    // Toggle the eventKey in the state
    let updatedState;
    if (activeEventKey.includes(eventKey)) {
      updatedState = activeEventKey.filter((key) => key !== eventKey);
    } else {
      updatedState = [...activeEventKey, eventKey];
    }
    setActiveEventKey(updatedState);
    // Save state to local storage
    localStorage.setItem("accordionState", JSON.stringify(updatedState));
  };

  const handleOuterAccordionChange = (eventKey) => {
    let updatedState;
    if (outerActiveEventKey.includes(eventKey)) {
      updatedState = outerActiveEventKey.filter((key) => key !== eventKey);
    } else {
      updatedState = [...outerActiveEventKey, eventKey];
    }
    setOuterActiveEventKey(updatedState);
    localStorage.setItem("outerAccordionState", JSON.stringify(updatedState));
  };

  const handleInnerAccordionChange = (eventKey) => {
    let updatedState;
    if (innerActiveEventKey.includes(eventKey)) {
      updatedState = innerActiveEventKey.filter((key) => key !== eventKey);
    } else {
      updatedState = [...innerActiveEventKey, eventKey];
    }
    setInnerActiveEventKey(updatedState);
    localStorage.setItem("innerAccordionState", JSON.stringify(updatedState));
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    // Retrieve state from local storage
    const storedState = localStorage.getItem("accordionState");
    const storedOuterState = localStorage.getItem("outerAccordionState");
    const storedInnerState = localStorage.getItem("innerAccordionState");
    if (storedState) {
      setActiveEventKey(JSON.parse(storedState));
    }
    if (storedOuterState) {
      setOuterActiveEventKey(JSON.parse(storedOuterState));
    }
    if (storedInnerState) {
      setInnerActiveEventKey(JSON.parse(storedInnerState));
    }
  }, []);

  const { location } = props;

  const authUser = useSelector((state) => state.auth);
  const SIDEBAR_NAV_MENU_ITEMS =
    authUser.currentUser == "Admin"
      ? ADMIN_SIDEBAR_NAV_MENU_ITEMS
      : ESS_SIDEBAR_NAV_MENU_ITEMS;

  function getClass(child, i) {
    const matched = location.pathname === child.path;
    if (matched && accordionRef.current) {
      return "active";
    }
  }

  return (
    <div
      className={`sidebar-wrapper ${
        props.activeSidebar == true ? "sidebar-active" : ""
      }`}
    >
      <ul className="metismenu">
        <li className="d-flex justify-content-end align-items-center">
          <label
            htmlFor="calender"
            className="d-flex align-items-center position-raltive "
          >
            <input
              type="month"
              id="calender"
              ref={inputRef}
              onChange={handleDateChange}
              className="outline-none "
            />
          </label>
          <p
            className={`${
              activeMonth ? "bg-success" : "bg-danger"
            } rounded text-white px-2 mb-0`}
            style={{ fontSize: "8px" }}
          >
            {activeMonth ? "active" : "freeze"}
          </p>
        </li>
        {_.map(SIDEBAR_NAV_MENU_ITEMS.mainMenu, (item, i) => (
          <li key={i}>
            <Link
              to={item.path}
              className={`d-flex gap-2 align-items-center ${
                location.pathname === item.path ? "active" : ""
              }`}
              aria-expanded="false"
            >
              <div
                className={`${
                  props.activeSidebar == true ? "icon-active" : "icon"
                }`}
              >
                {item.icon}
              </div>
              <p
                className={`my-auto ${
                  props.activeSidebar == true
                    ? "dashboard-heading-active"
                    : "dashboard-heading"
                }`}
              >
                {item.name}
              </p>
            </Link>
          </li>
        ))}
        {SIDEBAR_NAV_MENU_ITEMS.subMenu.length > 0 && (
          <li className="p-0">
            <Accordion
              activeKey={activeEventKey}
              onSelect={handleAccordionChange}
              flush
              ref={accordionRef}
            >
              {_.map(SIDEBAR_NAV_MENU_ITEMS.subMenu, (item, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header>
                    <div
                      className={`${
                        props.activeSidebar == true ? "icon-active" : "icon"
                      }`}
                    >
                      {item.icon}
                    </div>
                    <p
                      className={`my-auto ${
                        props.activeSidebar == true
                          ? "dashboard-heading-active"
                          : "dashboard-heading"
                      }`}
                    >
                      {item.name}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush">
                      {_.map(item.children, (child, k) => (
                        <ListGroup.Item as="div" key={k}>
                          <Link
                            to={child.path}
                            className={getClass(child, i)}
                            aria-expanded="false"
                          >
                            <p className=" mb-0">{child.name}</p>
                          </Link>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </li>
        )}

        {/* {authUser.currentUser.userType == "Admin" */}
        {authUser.currentUser == "Admin"
          ? ADMIN_CONTROLS_NAV_MENU_ITEMS.subMenu.length > 0 && (
              <li className="p-0">
                <Accordion
                  activeKey={outerActiveEventKey}
                  onSelect={handleOuterAccordionChange}
                  flush
                  ref={accordionRef}
                >
                  {_.map(ADMIN_CONTROLS_NAV_MENU_ITEMS.subMenu, (item, i) => (
                    <Accordion.Item eventKey={i} key={i}>
                      {item.mainHeadingName && (
                        <Accordion.Header>
                          <div
                            className={`${
                              props.activeSidebar == true
                                ? "icon-active"
                                : "icon"
                            }`}
                          >
                            {item.mainIcon}
                          </div>
                          <p
                            className={`my-auto ${
                              props.activeSidebar == true
                                ? "dashboard-heading-active"
                                : "dashboard-heading"
                            }`}
                          >
                            {item.mainHeadingName}
                          </p>
                        </Accordion.Header>
                      )}

                      <Accordion.Body className=" small-text">
                        <Accordion
                          activeKey={innerActiveEventKey}
                          onSelect={handleInnerAccordionChange}
                          flush
                          ref={accordionRef}
                        >
                          {_.map(
                            ADMIN_CONTROLS_NAV_MENU_ITEMS.subMenu,
                            (item, i) => (
                              <Accordion.Item eventKey={i} key={i}>
                                <Accordion.Header>
                                  <div
                                    className={`small-text ${
                                      props.activeSidebar == true
                                        ? "icon-active"
                                        : "icon"
                                    }`}
                                  >
                                    {item.icon}
                                  </div>
                                  <p
                                    className={`small-text  ${
                                      props.activeSidebar == true
                                        ? "dashboard-heading-active"
                                        : "dashboard-heading"
                                    }`}
                                  >
                                    {item.name}
                                  </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup variant="flush">
                                    {_.map(item.children, (child, k) => (
                                      <ListGroup.Item as="div" key={k}>
                                        <Link
                                          to={child.path}
                                          className={getClass(child, i)}
                                          aria-expanded="false"
                                        >
                                          <p className="mb-0 small-text">
                                            {child.name}
                                          </p>
                                        </Link>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          )}
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </li>
            )
          : ""}
      </ul>
    </div>
  );
}

export default withRouter(SideNav);
