import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const mailRoutes = [
  {
    path: routes.mail.mailSettting,
    element: lazyLoadRoutes("mail/pages/mail-setting"),
  },
  {
    path: routes.mail.ThirdPartymailSettting,
    element: lazyLoadRoutes("mail/pages/third-party-mail-setting"),
  },
  {
    path: routes.mail.mailer,
    element: lazyLoadRoutes("mail/pages/mailer"),
  },
  {
    path: routes.mail.emailTemplate,
    element: lazyLoadRoutes("mail/pages/email-template"),
  },
];
