import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const onBoardingRoutes = [
  {
    path: routes.onBoarding.onBoardingDashboard,
    element: lazyLoadRoutes("onBoarding/pages/dashboard"),
  },
  {
    path: routes.onBoarding.letterTemplate,
    element: lazyLoadRoutes("onBoarding/pages/letter-template"),
  },
  {
    path: routes.onBoarding.assignLetter,
    element: lazyLoadRoutes("onBoarding/pages/assign-letter"),
  },
  {
    path: routes.onBoarding.offeredList,
    element: lazyLoadRoutes("generateOffer/pages/candidateList"),
  },
  {
    path: routes.onBoarding.offeredAdd,
    element: lazyLoadRoutes("generateOffer/pages/candidateAdd"),
  },
  {
    path: `${routes.onBoarding.offeredEdit}/:id`,
    element: lazyLoadRoutes("generateOffer/pages/candidateEdit"),
  },
  {
    path: `${routes.onBoarding.offeredSalaryStructure}`,
    element: lazyLoadRoutes("generateOffer/pages/salaryStructure"),
  },
  {
    path: routes.onBoarding.offeredAcceptCandidateList,
    element: lazyLoadRoutes("generateOffer/pages/offerAcceptCandidate"),
  },
  {
    path: routes.onBoarding.employeeAcceptedDetails,
    element: lazyLoadRoutes("employee-accepted-onboarding/add"),
  },
  {
    path: routes.onBoarding.onBoardingApprover,
    element: lazyLoadRoutes("onBoarding/pages/onboarding-approver"),
  },
];
